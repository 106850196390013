<script setup lang="ts">
import { useStore } from '@nanostores/vue';
import { cartStore, removeItem, setCartOpen, updateItemQuantity } from '@/stores/cartStore';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetDescription, SheetTrigger } from '@/components/ui/sheet';
import { Button } from '@/components/ui/button';
import { formatNumber } from '@/utils/formatter';

const $cartStore = useStore(cartStore);

const props = defineProps<{
    productImagesByProductId: Record<string, string>;
}>();
</script>

<template>
    <Sheet :open="$cartStore.isOpen" @update:open="setCartOpen">
        <SheetTrigger aria-label="Warenkorb" class="relative">
            <slot name="trigger" />
            <span v-if="$cartStore.cart?.totalQuantity"
                class="absolute -top-1 -right-1 bg-primary text-primary-foreground rounded-full w-4 h-4 text-xs flex items-center justify-center">
                {{ $cartStore.cart.totalQuantity }}
            </span>
        </SheetTrigger>

        <SheetContent class="w-full sm:max-w-lg">
            <SheetHeader>
                <SheetTitle>Warenkorb</SheetTitle>
                <SheetDescription>
                    <span class="sr-only">Hier findest du alle Produkte, die du in deinen Warenkorb gelegt hast. Du kannst die Menge der Produkte ändern oder Produkte entfernen.</span>
                </SheetDescription>
            </SheetHeader>

            <div v-if="$cartStore.cart?.lines.length" class="flex flex-col gap-4 mt-4">
                <div v-for="item in $cartStore.cart.lines" :key="item.id" class="flex gap-4">
                    
                    <img width="64" height="64" :src="props.productImagesByProductId[item.merchandise.product.id]" :alt="item.merchandise.product.title"
                        class="w-20 h-20 object-cover rounded-full" />

                    <div class="flex flex-col flex-1">
                        <h3 class="font-medium">{{ item.merchandise.product.title }}</h3>
                        <p class="text-sm text-gray-500">{{ item.merchandise.title }}</p>

                        <div class="flex items-center gap-2 mt-2">
                            <Button variant="outline" size="icon"
                                @click="item.id && item.merchandise.id && updateItemQuantity(item.id, item.merchandise.id, item.quantity - 1)">
                                -
                            </Button>
                            <span>{{ item.quantity }}</span>
                            <Button variant="outline" size="icon"
                                @click="item.id && item.merchandise.id && updateItemQuantity(item.id, item.merchandise.id, item.quantity + 1)">
                                +
                            </Button>
                            <Button variant="ghost" size="icon" class="ml-auto" @click="item.id && removeItem(item.id)">
                                <slot name="remove" />
                            </Button>
                        </div>
                    </div>
                </div>

                <div class="mt-auto pt-4 border-t">
                    <div class="flex justify-between mb-2">
                        <span>Zwischensumme</span>
                        <span>{{ formatNumber(Number($cartStore.cart.cost.subtotalAmount.amount), {
                            style: 'currency',
                            currency: $cartStore.cart.cost.subtotalAmount.currencyCode
                        }) }}</span>
                    </div>
                    <Button size="lg" class="w-full" asChild>
                        <a :href="$cartStore.cart.checkoutUrl">Zur Kasse</a>
                    </Button>
                </div>
            </div>

            <div v-else class="flex flex-col items-center justify-center h-[50vh]">
                <slot name="trigger" />
                <p class="mt-4 text-3xl font-medium">Nix drin.</p>
                <p class="mt-4 text-gray-500">Dein Warenkorb ist noch leer..</p>
            </div>
        </SheetContent>
    </Sheet>
</template>